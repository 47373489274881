import styled from 'styled-components';

export interface SoundCloudProps {
  height?: string;
}

export const SoundCloud = styled.iframe<SoundCloudProps>`
  width: 100%;
  height: ${({height}) => (height ? height : '400px')};
  margin: 0;
  border: none;
  border-radius: 0;
`;
