import React, {FunctionComponent} from 'react';
import {StyledArticle, ArticleBar} from './Article.styles';
import {useBeenOnScreen} from '../../hooks/useBeenOnScreen';

interface ArticleProps {
  title: string;
  testID?: string;
}

export const Article: FunctionComponent<ArticleProps> = ({
  children,
  title,
  testID,
}) => {
  const [animate, ref] = useBeenOnScreen<HTMLHeadingElement>();

  return (
    <StyledArticle animate={animate} data-testid={testID}>
      <ArticleBar animate={animate} className="article-bar" />
      <h3 ref={ref}>{title}</h3>
      <p>{children}</p>
    </StyledArticle>
  );
};
