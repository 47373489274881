import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';

export const CrossIcon: FunctionComponent<IconProps> = ({
  colour = Theme.colours.white,
  width = '14px',
  height = '14px',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={colour}
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        fill={colour}
        fillRule="nonzero"
        points="14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7"
      />
    </g>
  </svg>
);
