import React, {FunctionComponent} from 'react';
import {Card, CardStyle} from '../Card';
import {Theme} from '../../theme/Theme';
import styled from 'styled-components';
import {Button} from '../Button';

const {colours, breakpoints, space} = Theme;

interface BannerProps {
  /** Title is the main tagline of the banner */
  title: string;
  /** Button is the cta that appears within the button */
  button: string;
  /** href is the url the CTA button navigates to */
  href: string;
  /** use to prefix the data-testid */
  testID?: string;
}

const Title = styled.h3`
  @media screen and (min-width: ${breakpoints[0]}px) {
    margin: auto;
    flex-shrink: 2;
    padding-right: ${space[2]}px;
  }
`;

export const Banner: FunctionComponent<BannerProps> = ({
  title,
  button,
  href,
  testID = 'banner',
}) => (
  <Card
    cardStyle={CardStyle.SOLID}
    colour={colours.red}
    flexDirection="column"
    justifyContent="center"
    breakpointProps={{breakpoint: breakpoints[0], flexDirection: 'row'}}
  >
    <Title data-testid={`${testID}-title`}>{title}</Title>
    <Button
      background={colours.grey}
      style={{margin: 'auto 0', flexShrink: 0}}
      href={href}
      data-testid={`${testID}-button`}
    >
      {button}
    </Button>
  </Card>
);
