import React, {FunctionComponent, Fragment, useEffect, useState} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {Hero} from '../components/Hero';
import {graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';
import {Theme} from '../theme/Theme';
import {detect} from '../utils/detect';
import {Carousel} from '../components/Carousel';
import {CollectiveSupportedDevices} from '../constants/paths';
import {Article, Articles} from '../components/Article';
import {VideoPlayer} from '../components/VideoPlayer';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {ButtonVariant} from '../components/Button/ButtonVariant';
import {Button} from '../components/Button';
import {Group} from '../components/Group';
import {AudioPlayer} from '../components/AudioPlayer';
import {Section} from '../components/Section';
import {SectionColours} from '../components/Section/SectionColours';
import {Banner} from '../components/Banner';
import {
  HeroBackgroundVideo,
  HeroBackgroundVideoWrapper,
} from '../components/Hero/Hero.styles';
import {FooterContent} from '../types/FooterContent';
import {createApi} from '../lib/api';
import {PaymentsApi, PlansListPlansInner} from '@focusrite-novation/ampify-api';
import {ProductPrice} from '../components/ProductPrice';
import {getConfigValue} from '../utils/countly';
import {formatBundleId} from '../utils/format-software-id';
import {renderPageContent} from '../utils/rich-text-renderer';
import {DownloadModal} from '../components/DownloadModal/DownloadModal';
import {ContentfulProduct} from '../types/ContentfulProductContent';
import {shouldContinueToPurchase} from '../utils/payment-flow';
import {fetchPlans, fetchPurchases} from '../lib/my-account';

const {colours, breakpoints} = Theme;

const PlaceholderParagraph = styled.p`
  color: ${Theme.colours.white};
  font-size: 18px;
  line-height: 28px;
  margin: 20px 0;
  padding-bottom: '20px';
`;

export const AlternateOutlineButton = styled(Button)`
  :focus-visible {
    outline: #469dad solid 3px;
  }
`;

interface ProductPageProps {
  pageContext: {
    contentfulProductContent: ContentfulProduct;
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
    gettingStartedVideoUrl: string;
    ampifyApiBaseUrl: string;
    auth: {
      client_id: string;
      scope: string;
      customerPortalBaseUrl: string;
    };
  };
  site: {
    siteMetdadata: {
      releases: {
        baseUrl: string;
      };
    };
  };
}

const SystemRequirement = styled.span`
  p {
    margin-bottom: 0;
  }
  a {
    color: ${Theme.colours.white};
  }
`;
const ProductPage: FunctionComponent<ProductPageProps> = (props) => {
  const [shouldContinueFlow, setShouldContinueFlow] = useState<boolean>(true);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<PlansListPlansInner[]>([]);
  const [bundleDownloadOs, setBundleDownloadOs] =
    useState<CollectiveSupportedDevices>(
      detect.os() as CollectiveSupportedDevices
    );
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [videoUrlVersion, setVideoUrlVersion] = useState('default');
  const [showMobileMessage, setShowMobileMessage] = useState(false);

  const baseUrl = props.pageContext.ampifyApiBaseUrl;
  const auth = {
    client_id: props.pageContext.auth.client_id,
    scope: props.pageContext.auth.scope,
  };

  useEffect(() => {
    const configValue = getConfigValue('videoUrlVersion');
    if (configValue) {
      setVideoUrlVersion(configValue);
    }
    setShowMobileMessage(
      Boolean(contentfulProduct.mobileDownloadMessage) &&
        !detect.isCollectiveSupportedOS()
    );
  }, []);

  const getAllPlans = async (api: PaymentsApi) => {
    try {
      const plans = await api.getPlans().then((res) => res.data);
      setPlans(plans.plans!);
    } catch (error) {
      console.error(error);
    }
  };

  const data = useStaticQuery(graphql`
    query ProductPageQuery {
      shadow: file(relativePath: {eq: "screenshot-shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 1198, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          ampifyApi {
            baseUrl
          }
          releases {
            baseUrl
          }
          ukVATMultiplier
        }
      }
    }
  `);

  const contentfulProduct = props.pageContext.contentfulProductContent.node;

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const api = createApi(PaymentsApi, baseUrl, auth);
      try {
        const [plans, purchases, softwareProducts] = await Promise.all([
          fetchPlans(api),
          fetchPurchases(api),
          api.getSoftwareProducts(),
          getAllPlans(api),
        ]);

        const continueFlow = await shouldContinueToPurchase(
          contentfulProduct.softwareId,
          softwareProducts.data,
          purchases,
          plans
        );
        setShouldContinueFlow(continueFlow);
      } catch (e) {
        setShouldContinueFlow(true);
      }
      setButtonIsLoading(false);
    })();
  }, []);

  const handleShowDownloadModal = async (os: CollectiveSupportedDevices) => {
    setBundleDownloadOs(os);
    setShowDownloadModal(true);
  };

  const alternateVideoId = props.pageContext.gettingStartedVideoUrl;

  return (
    <Layout
      {...props.pageContext.contentfulFooterContent}
      customerPortalBaseUrl={props.pageContext.customerPortalBaseUrl}
    >
      <SEO
        title={contentfulProduct.title}
        description={contentfulProduct.heroDescription.heroDescription}
        meta={[
          {
            property: 'og:url',
            content: 'https://collective.focusrite.com',
          },
          {
            property: 'og:image',
            content: `https://collective.focusrite.com/images/${contentfulProduct.slug}-1200-630.png`,
          },
          {
            property: 'og:image:width',
            content: '1200',
          },
          {
            property: 'og:image:height',
            content: '630',
          },
          {
            property: 'og:image',
            content: `https://collective.focusrite.com/images/${contentfulProduct.slug}-thumb-800-800.png`,
          },
          {
            property: 'og:image:width',
            content: '800',
          },
          {
            property: 'og:image:height',
            content: '800',
          },
          {
            property: 'og:image:alt',
            content: contentfulProduct.title,
          },
          {
            property: 'og:image:type',
            content: 'image/png',
          },
          {
            property: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            property: 'twitter:image',
            content: `https://collective.focusrite.com/images/${contentfulProduct.slug}-1200-630.png`,
          },
        ]}
      />
      <Hero
        fluid={contentfulProduct.heroBackgroundImage.fluid}
        extraTall
        data-testid="hero-image"
      >
        {detect.isDesktop() && (
          <HeroBackgroundVideoWrapper>
            <HeroBackgroundVideo autoPlay loop muted>
              <source
                src={contentfulProduct.heroBackgroundVideo.file.url}
                type="video/mp4"
              />
            </HeroBackgroundVideo>
          </HeroBackgroundVideoWrapper>
        )}
        <img
          src={contentfulProduct.productLogo.file.url}
          width="80%"
          height="48px"
          data-testid="product-logo"
          style={{marginTop: '50px'}}
        />
        {contentfulProduct.heroTitle && (
          <h2 data-testid="hero-title">{contentfulProduct.heroTitle}</h2>
        )}
        {contentfulProduct.heroDescription && (
          // A div wrapper is needed here as when passing the data-testid to
          // the PlaceholderParagraph an error occurs causing tests to fail.
          // Some investigation was done around this issue however there didn't
          // seem to be a solution other than wrapping it at this time.
          <div data-testid="hero-description">
            <PlaceholderParagraph>
              {contentfulProduct.heroDescription.heroDescription}
            </PlaceholderParagraph>
          </div>
        )}
        {showMobileMessage && (
          <PlaceholderParagraph>
            {contentfulProduct.mobileDownloadMessage}
          </PlaceholderParagraph>
        )}
      </Hero>

      <ProductPrice
        ampifyBaseUrl={data.site.siteMetadata.ampifyApi.baseUrl}
        page={props.pageContext.contentfulProductContent}
        data={data}
        variant={
          buttonIsLoading ? ButtonVariant.LOADING : ButtonVariant.PRIMARY
        }
        disabled={buttonIsLoading}
        handleDownloadButtonClick={handleShowDownloadModal}
        buyButtonLocation={
          shouldContinueFlow
            ? `/plan-selection/${formatBundleId(contentfulProduct.softwareId)}`
            : '/my-account'
        }
      />

      <Carousel
        images={contentfulProduct.slideshow.map(
          (slideshowImage) => slideshowImage.fluid
        )}
        shadow={(data as any).shadow.childImageSharp.fluid}
        titles={contentfulProduct.slideshow.map(({title}) => title)}
      />
      <Articles paddingSides>
        {contentfulProduct.productFeatures.map((feature, index) => (
          <Article
            title={feature.title}
            testID={`feature-${index + 1}`}
            key={`feature${index}`}
          >
            {feature.description.description}
          </Article>
        ))}
      </Articles>
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingTop>
        <VideoPlayer
          playerID={contentfulProduct.slug}
          videoID={
            videoUrlVersion === 'default' || !alternateVideoId
              ? contentfulProduct.mainVideoId
              : alternateVideoId
          }
          testID="video-player"
        />
      </Gutters>
      <Section>
        <Gutters maxWidth={GutterMaxWidth.NORMAL} paddingSides>
          <Group>
            <h1 data-testid="playlistTitle">
              {contentfulProduct.soundcloudPlaylistTitle}
            </h1>
            <span data-testid="playlistDescription">
              {contentfulProduct.soundcloudPlaylistDescription}
            </span>
            <AudioPlayer playlist={contentfulProduct.soundcloudPlaylistId} />
          </Group>
        </Gutters>
      </Section>

      {contentfulProduct.highlightTitle && (
        <Gutters maxWidth={GutterMaxWidth.NORMAL} paddingSides>
          <Group data-testid="highlight">
            <h2>{contentfulProduct.highlightTitle}</h2>
            <span>{contentfulProduct.highlightBody.highlightBody}</span>
            <Group
              flexDirection="column"
              justifyContent="flex-start"
              breakpointProps={{
                breakpoint: breakpoints[0],
                flexDirection: 'row',
              }}
            >
              {contentfulProduct.highlightButtons.map(
                ({buttonText, buttonUrl}, index) => (
                  <AlternateOutlineButton
                    background={colours.grey}
                    href={buttonUrl}
                    key={`highlight-button-${index}`}
                    data-testid={`highlight-button-${index}`}
                  >
                    {buttonText}
                  </AlternateOutlineButton>
                )
              )}
            </Group>
          </Group>
        </Gutters>
      )}

      <Section
        flip={!!contentfulProduct.highlightTitle}
        colours={SectionColours.ALTERNATE}
      >
        <Gutters paddingSides>
          <Group>
            <h2>{contentfulProduct.systemRequirementsTitle}</h2>
            {contentfulProduct.systemRequirements.map(
              ({title, body}, index) => (
                <Fragment key={`requirements-${index}`}>
                  <h5>{title}</h5>
                  <SystemRequirement>
                    {renderPageContent(body, {
                      isBlogPost: false,
                      withoutListStyles: true,
                    })}
                  </SystemRequirement>
                </Fragment>
              )
            )}
          </Group>
        </Gutters>
      </Section>
      {showDownloadModal && (
        <DownloadModal
          os={bundleDownloadOs as CollectiveSupportedDevices}
          page="product"
          plans={plans}
          baseUrl={data.site.siteMetadata?.releases?.baseUrl!}
          handleClose={() => setShowDownloadModal(false)}
          softwareId={contentfulProduct.softwareId}
        />
      )}
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <Banner
          title={contentfulProduct.gettingStartedTitle}
          button={contentfulProduct.gettingStartedButtonText}
          href={`./${contentfulProduct.slug}/getting-started`}
          testID="getting-started"
        />
      </Gutters>
    </Layout>
  );
};

export default ProductPage;
