import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
import Img, {GatsbyImageProps} from 'gatsby-image';
import {Button} from '../Button';

const {space, borderWidths, zIndices, breakpoints} = Theme;

export const StyledCarousel = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakpoints[0]}px) {
    top: -20vw;
  }
`;

const {colours} = Theme;

export const IconButton = styled(Button)`
  padding: ${space[2]}px;
`;

interface ThumbnailGroupProps {
  totalImages: number;
}

export const ThumbnailGroup = styled.div<ThumbnailGroupProps>`
  max-width: ${({totalImages}) => `${totalImages * 120}px`};
  margin: 0 auto;
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface ThumbnailProps {
  selected?: boolean;
}

export const Thumbnail = styled.div<ThumbnailProps>`
  width: 100px;
  height: ${100 + borderWidths[2]}px;
  max-width: 14vw;
  max-height: calc(14vw + ${borderWidths[2]}px);

  cursor: ${({selected}) => (selected ? 'not-allowed' : 'pointer')};
  border-bottom: ${borderWidths[2]}px solid
    ${({selected}) => (selected ? colours.grey : colours.white)};
  transition: border 0.3s ease-out;
  outline: none;
  margin: 0;

  opacity: ${({selected}) => (selected ? 0.45 : 1)};

  &:hover,
  &:focus {
    div {
      filter: brightness(70%);
    }
    border-color: ${({selected}) => (selected ? colours.white : colours.blue)};
  }

  overflow: hidden;

  div {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
`;

export const Screenshot = styled(Img)<GatsbyImageProps>`
  max-width: 600px;
  margin: 0 auto;
  width: 90vw;
`;

export const Shadow = styled(Img)<GatsbyImageProps>`
  max-width: 710px;
  width: 100%;
  position: absolute;
  top: -28px;
  z-index: ${zIndices.behind};
  @media screen and (max-width: ${breakpoints[1]}px) {
    top: -4vw;
  }
`;

export const LargeImage = styled(Img)<GatsbyImageProps>`
  margin: 0 auto;
  width: 100%;
`;
