import styled, {css, keyframes} from 'styled-components';
import {Theme} from '../../theme/Theme';
import {Gutters} from '../Spacing/Gutters';

const {colours, borderWidths, space, breakpoints} = Theme;
const borderColours = [
  colours.red,
  colours.orange,
  colours.yellow,
  colours.blue,
  colours.lightGrey,
];

const article: any = {};

borderColours.forEach(
  (colour, index) =>
    (article[`:nth-child(${index + 1})`] = {
      '.article-bar': {
        background: colour,
        animationDelay: `${(index + 1) * 100}ms`,
      },
      'h3': {
        animationDelay: `${(index + 1) * 120}ms`,
      },
    })
);

export const Articles = styled(Gutters)({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
  marginTop: `${Theme.space[6]}px`,
  article,
});

const animateBar = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }  
`;

interface ArticleBarProps {
  animate: boolean;
}

export const ArticleBar = styled.div<ArticleBarProps>`
  width: 0%;
  height: ${borderWidths[2]}px;
  margin-bottom: ${space[4]}px;
  ${({animate}) =>
    animate
      ? css`
          animation: ${animateBar} 600ms ease-out forwards;
        `
      : ''};
`;

const animateUp = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }  
`;

interface StyledArticleProps {
  animate: boolean;
}

export const StyledArticle = styled.article<StyledArticleProps>`
  padding: 0;
  width: 100%;
  margin-bottom: ${space[4]}px;

  h3,
  p {
    opacity: 0;
    ${({animate}) =>
      animate
        ? css`
            animation: ${animateUp} 600ms ease-out forwards;
          `
        : ''};
  }

  @media screen and (min-width: ${breakpoints[0]}px) {
    width: 45%;
    h3,
    p {
      padding: 0;
    }
  }
`;
