import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
import {createGradient} from '../../utils/colour';

const {colours, space, breakpoints} = Theme;

interface SectionBackgroundProps {
  colours: string[];
  flip: boolean;
}

export const SectionBackground = styled.div<SectionBackgroundProps>`
  position: relative;
  margin: ${space[6]}px 0;
  min-height: ${space[8]}px;
  padding: ${space[6]}px 0;
  background: ${colours.grey};

  :before,
  :after {
    transform: skewY(${({flip}) => (flip ? '' : '-')}4deg);
    background: ${colours.yellow};
    background: linear-gradient(
      ${({flip}) => (flip ? '-' : '')}31deg,
      ${({colours}) => createGradient(colours, 'lch').join(',')}
    );
    content: '';
    display: block;
    height: 50%;
    z-index: -2;
    position: absolute;
    left: 0;
    right: 0;
  }

  :before {
    top: 0;
    transform-origin: ${({flip}) => (flip ? '100%' : '0%')} 0;
  }
  :after {
    bottom: 0;
    transform-origin: ${({flip}) => (flip ? '0' : '100%')} 0;
  }
`;

interface StyledSectionProps {
  flip: boolean;
}

export const StyledSection = styled.section<StyledSectionProps>`
  background-color: ${colours.grey};
  position: relative;
  margin: ${space[7]}px 0;
  color: ${colours.white};
  min-height: ${space[8]}px;
  z-index: 1;

  @media screen and (min-width: ${breakpoints[1]}px) {
    margin: ${space[8]}px 0;
  }

  @media screen and (min-width: ${breakpoints[3]}px) {
    margin: ${space[8]}px 0;
  }

  :before,
  :after {
    transform: skewY(${({flip}) => (flip ? '-' : '')}4deg);
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  :before {
    top: 0;
    transform-origin: ${({flip}) => (flip ? '0%' : '100%')} 0;
  }
  :after {
    bottom: 0;
    transform-origin: ${({flip}) => (flip ? '100%' : '0')} 0;
  }
`;
