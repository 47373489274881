import React, {FunctionComponent, MouseEventHandler} from 'react';
import {ZoomIcon} from '../Icon/ZoomIcon';
import {ZoomButtonContainer} from './ZoomButton.styles';

interface ZoomButtonProps {
  onClick: MouseEventHandler;
}

export const ZoomButton: FunctionComponent<ZoomButtonProps> = ({
  children,
  onClick,
}) => (
  <ZoomButtonContainer onClick={onClick}>
    <ZoomIcon />
    {children}
  </ZoomButtonContainer>
);
