import styled from 'styled-components';

export const ZoomButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  svg {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 400ms ease-out;
  }

  :hover,
  :active {
    svg {
      opacity: 1;
    }
    img {
      filter: brightness(50%);
    }
  }
`;
