import React, {FunctionComponent} from 'react';
import {StyledHero, Container, StyledHeroProps} from './Hero.styles';
import {GutterMaxWidth} from '../Spacing/Gutters';

interface HeroProps extends StyledHeroProps {}

export const Hero: FunctionComponent<HeroProps> = ({
  children,
  flexDirection = 'column',
  ...props
}) => {
  return (
    <StyledHero flexDirection={flexDirection} {...props}>
      <Container maxWidth={GutterMaxWidth.SMALL} paddingSides>
        {children}
      </Container>
    </StyledHero>
  );
};
