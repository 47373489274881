import React, {FunctionComponent} from 'react';
import {StyledSection, SectionBackground} from './Section.styles';
import {SectionColours} from './SectionColours';

interface SectionProps {
  colours?: string[];
  flip?: boolean;
}

export const Section: FunctionComponent<SectionProps> = (props) => {
  const colours = props.colours ?? SectionColours.DEFAULT;

  return (
    <StyledSection flip={!!props.flip}>
      <SectionBackground flip={!!props.flip} colours={colours}>
        {props.children}
      </SectionBackground>
    </StyledSection>
  );
};
