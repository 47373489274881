import styled from 'styled-components';
import Theme from '../../theme';
import {Button} from '../Button';

const {colours, shadows, space, zIndices, breakpoints, fontSizes} = Theme;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${colours.red};
  padding: ${space[2]}px;
  width: ${space[5]}px;
  height: ${space[5]}px;
  justify-content: center;
`;

export const ModalPanel = styled.div`
  position: relative;
  display: flex;
  background: ${colours.white};
  flex-direction: column;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  min-width: 90%;
  padding: ${space[4]}px;
  box-shadow: ${shadows[0]};

  @media screen and (max-width: ${breakpoints[1]}px) {
    min-width: 95%;
  }

  @media screen and (max-width: ${breakpoints[0]}px) {
    width: 100%;
    padding: ${space[4]}px 0;
  }
`;

export const ModalFrame = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.64);
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${zIndices.modal};
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: ${space[4]}px;
`;

export const ModalFooter = styled.div`
  padding: 0 ${space[2]};
  padding-top: ${space[4]}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  div {
    height: 60px;
    padding: 20px ${space[2]};
  }

  span {
    padding: 0 ${space[3]}px;
    font-size: ${fontSizes[2]}px;
    color: ${colours.grey};
  }

  @media screen and (max-width: ${breakpoints[1]}px) {
    min-width: 95%;
  }

  @media screen and (max-width: ${breakpoints[0]}px) {
    width: 100%;
  }
`;
