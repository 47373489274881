import styled from 'styled-components';
import Theme from '../../theme';
import {Gutters} from '../Spacing/Gutters';

export const Container = styled(Gutters)`
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: ${Theme.colours.white};
  padding: ${Theme.space[4]}px;
  box-shadow: ${Theme.shadows[0]};
  transform: translateY(-50%);

  @media (max-width: ${Theme.breakpoints[1]}px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${Theme.breakpoints[0]}px) {
    margin-bottom: ${Theme.space[6]}px;
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  @media (max-width: ${Theme.breakpoints[0]}px) {
    flex-direction: column;
  }
`;

export const PriceHeading = styled.p`
  font-size: ${Theme.fontSizes[2]}px;
`;

export const PriceAmount = styled.p`
  font-weight: ${Theme.fontWeights.bold};
  font-size: ${Theme.fontSizes[5]}px;
`;

export const PriceDiscounted = styled.span`
  text-decoration: line-through;
  color: ${Theme.colours.lightGrey};
`;

export const PriceDuration = styled.div`
  font-size: ${Theme.fontSizes[2]}px;
`;

export const Purchase = styled.div``;
export const Monthly = styled.div`
  position: relative;

  @media (max-width: ${Theme.breakpoints[0]}px) {
    margin-top: 1rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > :first-child {
    margin-right: ${Theme.space[2]}px;
  }

  @media (max-width: ${Theme.breakpoints[1]}px) {
    margin-top: ${Theme.space[4]}px;
    justify-content: center;
  }
`;

export const FreeTrial = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1;
  font-size: ${Theme.fontSizes[0]}px;
  font-weight: ${Theme.fontWeights.bold};
  color: ${Theme.colours.white};
  background-color: ${Theme.colours.orange};
  padding: ${Theme.space[2]}px ${Theme.space[4]}px;
  border-radius: 2px;
  transform: translateX(-50%);
`;

export const DiscountBanner = styled.div`
  position: absolute;
  bottom: -35px;
  left: 25px;
  padding: ${Theme.space[1]}px ${Theme.space[3]}px;
  background-color: ${Theme.colours.orange};
  color: ${Theme.colours.white};
  border-radius: ${Theme.space[1]}px;
  font-weight: bold;
  font-size: ${Theme.fontSizes[1]}px;
  transform: translateY(-70%);
  text-align: center;
  line-height: 1.5;
  width: max-content;
  max-width: 80%;

  @media (max-width: ${Theme.breakpoints[1]}px) {
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, -70%);
  }
  @media (max-width: 395px) {
    bottom: -130px;
    left: 50%;
    transform: translate(-50%, -70%);
  }
`;
