import {PaymentsApi} from '@focusrite-novation/ampify-api';
import {Currency, detect} from '@focusrite-novation/ampify-web-ui';
import {useLocation} from '@reach/router';
import {navigate} from 'gatsby-link';
import React, {FC, useEffect, useState} from 'react';
import {CollectiveSupportedDevices, pathFor} from '../../constants/paths';
import {isChosenPlugin} from '../../lib/payment';
import {ContentfulProduct} from '../../types/ContentfulProductContent';
import {
  formatPrice,
  getPerpetualPrice,
  getRTOPrice,
} from '../../utils/get-plan-pricing';
import {Button} from '../Button';
import {ButtonVariant} from '../Button/ButtonVariant';
import {DownloadButton} from '../DownloadButton';
import * as Styles from './ProductPrice.styles';

interface ProductPriceProps {
  page: ContentfulProduct;
  data: any;
  variant: ButtonVariant;
  disabled: boolean;
  buyButtonLocation: string;
  handleDownloadButtonClick: (os: CollectiveSupportedDevices) => Promise<void>;
  ampifyBaseUrl: string;
}

export const ProductPrice: FC<ProductPriceProps> = ({
  page,
  data,
  variant,
  disabled,
  buyButtonLocation,
  ampifyBaseUrl,
  handleDownloadButtonClick,
}) => {
  const [perpetualPrice, setPerpetualPrice] = useState<number>();
  const [RTOPrice, setRTOPrice] = useState<number>();
  const location = useLocation();
  const softwareId = page.node.softwareId;

  useEffect(() => {
    (async function () {
      const ampifyApi = new PaymentsApi({
        basePath: ampifyBaseUrl,
        isJsonMime: () => true,
      });
      const perpetualPrice = await getPerpetualPrice(
        ampifyApi,
        page.node.softwareId
      );

      const RTOPrice = await getRTOPrice(ampifyApi, page.node.softwareId);

      setPerpetualPrice(Number(perpetualPrice));
      setRTOPrice(Number(RTOPrice));
    })();
  }, []);

  return (
    <Styles.Container paddingSides>
      {page.node.pricingBannerText &&
        page.node.pricingBannerText.length > 0 && (
          <Styles.DiscountBanner>
            {page.node.pricingBannerText}
          </Styles.DiscountBanner>
        )}
      <Styles.Prices>
        <Styles.Purchase>
          <Styles.PriceHeading>Purchase plugin</Styles.PriceHeading>
          {perpetualPrice && (
            <>
              {isChosenPlugin('fast-bundle', location) ? (
                <Styles.PriceAmount>
                  <Styles.PriceDiscounted>
                    {formatPrice(Currency.USD, perpetualPrice)}
                  </Styles.PriceDiscounted>{' '}
                  {formatPrice(Currency.USD, 251.89)} *
                </Styles.PriceAmount>
              ) : (
                <Styles.PriceAmount>
                  {formatPrice(Currency.USD, perpetualPrice)}
                </Styles.PriceAmount>
              )}
              <Styles.PriceDuration>
                / one time (plus taxes)
              </Styles.PriceDuration>
            </>
          )}
        </Styles.Purchase>
        <Styles.Monthly>
          <Styles.PriceHeading>Monthly Payments</Styles.PriceHeading>
          {RTOPrice && (
            <>
              {isChosenPlugin('fast-bundle', location) ? (
                <Styles.PriceAmount>
                  <Styles.PriceDiscounted>
                    {formatPrice(Currency.USD, RTOPrice)}
                  </Styles.PriceDiscounted>{' '}
                  {formatPrice(Currency.USD, 16.79)} *
                </Styles.PriceAmount>
              ) : (
                <Styles.PriceAmount>
                  {formatPrice(Currency.USD, RTOPrice)}
                </Styles.PriceAmount>
              )}
              <Styles.PriceDuration>
                / mo for 15 mo. (plus taxes)
              </Styles.PriceDuration>
            </>
          )}
        </Styles.Monthly>
      </Styles.Prices>
      <Styles.Actions>
        <Button
          disabled={disabled}
          variant={variant}
          data-testid="buy-now"
          onClick={() => navigate(buyButtonLocation)}
          style={{width: 'max-content', maxHeight: '44px'}}
        >
          Buy Now
        </Button>

        <DownloadButton
          os={detect.os()}
          onDownload={handleDownloadButtonClick}
          testID="download-button"
          buttonText="Download now"
          softwareId={softwareId}
        />
      </Styles.Actions>
    </Styles.Container>
  );
};
