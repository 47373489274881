import styled from 'styled-components';
import Theme from '../../theme';
import BackgroundImage, {IBackgroundImageProps} from 'gatsby-background-image';
import {Gutters} from '../Spacing/Gutters';

const {colours, space, breakpoints} = Theme;

export interface StyledHeroProps extends IBackgroundImageProps {
  /** Css pro that defines flex-direction */
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /** to define if the header needs to be extra tall so a subsequent component can overlay it */
  extraTall?: boolean;
}

export const StyledHero = styled(BackgroundImage)<StyledHeroProps>`
  background-color: ${colours.grey};
  color: ${colours.white};
  display: flex;
  text-align: center;
  flex-direction: ${({flexDirection}) => flexDirection};
  padding: ${({extraTall}) => (extraTall ? `50px 0 ${space[8]}px` : '100px 0')};
`;

export const Container = styled(Gutters)`
  margin-top: ${space[4]}px;
`;

export const HeroBackgroundVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
`;

export const HeroBackgroundVideo = styled.video`
  display: none;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @media screen and (min-width: ${breakpoints[0]}px) {
    display: block;
  }
`;
