import React, {FunctionComponent} from 'react';
import {SoundCloud} from './AudioPlayer.styles';

export interface AudioPlayerProps {
  playlist?: string;
  height?: string;
}

export const AudioPlayer: FunctionComponent<AudioPlayerProps> = ({
  playlist = '355769372',
  height,
}) => {
  return (
    <SoundCloud
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      height={height}
      src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${playlist}&color=%23a47c74&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
    />
  );
};
