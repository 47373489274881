import React, {FunctionComponent, ReactElement} from 'react';
import {
  ModalHeader,
  ModalFooter,
  ModalPanel,
  ModalFrame,
  CloseButton,
} from './Modal.styles';
import {CrossIcon} from '../Icon/CrossIcon';

interface ModalProps {
  header?: () => ReactElement;
  footer?: () => ReactElement;
  handleClose?: () => void;
}

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  header,
  footer,
  handleClose,
}) => (
  <ModalFrame onClick={handleClose} tabIndex={-1}>
    <ModalPanel onClick={(event) => event.stopPropagation()} role="dialog">
      <ModalHeader>
        {header && header()}
        <CloseButton onClick={handleClose}>
          <CrossIcon />
        </CloseButton>
      </ModalHeader>
      {children}
      {footer && <ModalFooter>{footer()}</ModalFooter>}
    </ModalPanel>
  </ModalFrame>
);
