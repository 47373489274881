import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {
  Screenshot,
  Container,
  Shadow,
  IconButton,
  LargeImage,
  StyledCarousel,
  Thumbnail,
  ThumbnailGroup,
} from './Carousel.styles';
import {FluidObject} from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import {ZoomButton} from '../ZoomButton';
import {Modal} from '../Modal';
import {ArrowIcon} from '../Icon';
import {Orientation} from '../Icon/ArrowIcon';
import {useStateRef} from '../../hooks/useStateRef';

interface CarouselProps {
  images: FluidObject[];
  titles: string[];
  shadow?: FluidObject;
}

enum KeyCode {
  ESCAPE = 27,
  RIGHT = 39,
  LEFT = 37,
}

export const Carousel: FunctionComponent<CarouselProps> = ({
  images,
  titles,
  shadow,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentIndex, setIndex, indexRef] = useStateRef<number>(0);

  const next = () =>
    setIndex(indexRef.current === images.length - 1 ? 0 : indexRef.current + 1);

  const previous = () =>
    setIndex(indexRef.current === 0 ? images.length - 1 : indexRef.current - 1);

  useEffect(() => {
    const handleKeypress = ({keyCode}: KeyboardEvent) => {
      switch (keyCode) {
        case KeyCode.ESCAPE:
          setModalVisible(false);
          break;
        case KeyCode.LEFT:
          previous();
          break;
        case KeyCode.RIGHT:
          next();
          break;
      }
    };

    document.addEventListener('keydown', handleKeypress);
    return () => document.removeEventListener('keydown', handleKeypress);
  }, []);

  return (
    <Fragment>
      {modalVisible && (
        <Modal
          handleClose={() => setModalVisible(false)}
          header={() => <span>{titles[currentIndex]}</span>}
          footer={() => (
            <>
              <IconButton onClick={previous}>
                <ArrowIcon orientation={Orientation.RIGHT} />
              </IconButton>
              <span>
                {currentIndex + 1} of {images.length}
              </span>
              <IconButton onClick={next}>
                <ArrowIcon orientation={Orientation.LEFT} />
              </IconButton>
            </>
          )}
        >
          <LargeImage
            fluid={images[currentIndex]}
            imgStyle={{objectFit: 'contain'}}
          />
        </Modal>
      )}
      <StyledCarousel>
        <Container>
          <ZoomButton onClick={() => setModalVisible(true)}>
            <Screenshot fluid={images[currentIndex]} />
          </ZoomButton>
          <Shadow fluid={shadow as FluidObject} />
        </Container>

        <Container>
          <ThumbnailGroup totalImages={images.length}>
            {images.map((image, index) => (
              <Thumbnail
                key={`thumb_${index}`}
                tabIndex={0}
                selected={index === currentIndex}
                onKeyDown={({key}) => {
                  if (key === 'Enter') {
                    setIndex(index);
                  }
                }}
                onClick={() => setIndex(index)}
              >
                <BackgroundImage fluid={image} />
              </Thumbnail>
            ))}
          </ThumbnailGroup>
        </Container>
      </StyledCarousel>
    </Fragment>
  );
};
